
.trend-post-card {
    .ant-card-cover {
        position: relative;
        overflow: hidden;
        background: #e8e8e8;
        height: 233px;

        img {
            object-fit: cover;
            height: 100%;
        }
    }
}

// Override avatar to fix scaled images

.ant-avatar > img {
    object-fit: cover;
}