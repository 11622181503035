// Variable
// --------------------------------------------------------

@unit: 5px !important;


// Margin
// --------------------------------------------------------

@import 'margin.less';

// Padding
// --------------------------------------------------------

@import 'padding.less';

.content-padding {
  padding: 40px;
}

// Layout
// --------------------------------------------------------

.min-layout-content {
  width: 100%;
  padding-left: 39px;
  padding-right: 39px;
}

// Initial layout override

@media (min-width: 1300px) {
  .min-layout-content {
    width: 1080px;
    padding: 0;
  }

  .small {
    width: 580px;
  }
}

// Display
// --------------------------------------------------------

.d-block {
  display: block;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}