
// Variables
// https://fontlibrary.org/en/font/trueno
// --------------------------------------------------------

.font-face { font-weight: normal; font-style: normal; }

@font-src: 'https://fontlibrary.org/assets/fonts/trueno/69d25d10193139d4a9cfc9d2196c23dc' ;

@font-face {
  &:extend(.ht-1);
  .font-face();
  src: url('@{font-src}/5258bb6653ec14bcbaed8e2c3452ef6c/TruenoLight.otf') format('opentype');
}

@font-face {
  &:extend(.ht-2);
  .font-face();
  src: url('@{font-src}/49c656db3c7dd8f38a90dd59b777baf8/TruenoLightItalic.otf') format('opentype');
}

@font-face {
  &:extend(.ht-3);
  .font-face();
  src: url('@{font-src}/7aba95e5ff98d5a275ced8ae58144c5e/TruenoRegular.otf') format('opentype');
}

@font-face {
  &:extend(.ht-4);
  .font-face();
  src: url('@{font-src}/75e1a3c3d0b921692ddf6a298bedab62/TruenoBold.otf') format('opentype');
}

@font-face {
  &:extend(.ht-5);
  .font-face();
  src: url('@{font-src}/97519f3659745f54b9ae215c273644bb/TruenoSemiBold.otf') format('opentype');
}

@font-face {
  &:extend(.ht-6);
  .font-face();
  src: url('@{font-src}/88d5fca36a740ee22e38ea829729e349/TruenoExtraBold.otf') format('opentype');
}


// Mixins
// --------------------------------------------------------

@sans-serif: Arial, sans-serif;

.type-light { font-family: 'TruenoLight', @sans-serif; }
.type-ilight { font-family: 'TruenoLightItalic', @sans-serif; }
.type-regular {font-family: 'TruenoRegular', @sans-serif; }
.type-bold { font-family: 'TruenoBold', @sans-serif; }
.type-sbold { font-family: 'TruenoSemiBold', @sans-serif; }
.type-ebold { font-family: 'TruenoExtraBold', @sans-serif; }


h1,h2,h3,h4,h5,h6 { 
  .type-ebold() !important;
  color: @color-black;
}

body { 
  .type-regular() !important;
  .font-face();
}

.text-primary{
  color: @primary-color;
}

// Font Sizes
// --------------------------------------------------------

.size-8px  { font-size: 8px; }
.size-9px  { font-size: 9px; }
.size-10px { font-size: 10px; }
.size-11px { font-size: 11px; }
.size-12px { font-size: 12px; }
.size-14px { font-size: 14px; }
.size-18px { font-size: 18px !important; }
.size-20px { font-size: 20px; }

// Letter Spacing
// --------------------------------------------------------

.spacing-05px { letter-spacing: 0.5px; }
.spacing-1px { letter-spacing: 1px; }

// Line Height
// --------------------------------------------------------

.leading-18px { line-height: 18px; }
.leading-24px { line-height: 24px; }
.leading-23 { line-height: 2.33; }

.uppercase { text-transform: uppercase; }

// Temporary

.clickable {
  cursor: pointer;
}

