.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.ml-4 {
  margin-left: 20px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.ml-5 {
  margin-left: 25px !important;
}
.mr-5 {
  margin-right: 25px !important;
}
.mt-6 {
  margin-top: 30px !important;
}
.mb-6 {
  margin-bottom: 30px !important;
}
.ml-6 {
  margin-left: 30px !important;
}
.mr-6 {
  margin-right: 30px !important;
}
.mt-7 {
  margin-top: 35px !important;
}
.mb-7 {
  margin-bottom: 35px !important;
}
.ml-7 {
  margin-left: 35px !important;
}
.mr-7 {
  margin-right: 35px !important;
}
.mt-8 {
  margin-top: 40px !important;
}
.mb-8 {
  margin-bottom: 40px !important;
}
.ml-8 {
  margin-left: 40px !important;
}
.mr-8 {
  margin-right: 40px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pr-1 {
  padding-right: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.pb-2 {
  padding-bottom: 10px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pr-2 {
  padding-right: 10px !important;
}
.pt-3 {
  padding-top: 15px !important;
}
.pb-3 {
  padding-bottom: 15px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pr-3 {
  padding-right: 15px !important;
}
.pt-4 {
  padding-top: 20px !important;
}
.pb-4 {
  padding-bottom: 20px !important;
}
.pl-4 {
  padding-left: 20px !important;
}
.pr-4 {
  padding-right: 20px !important;
}
.pt-5 {
  padding-top: 25px !important;
}
.pb-5 {
  padding-bottom: 25px !important;
}
.pl-5 {
  padding-left: 25px !important;
}
.pr-5 {
  padding-right: 25px !important;
}
.pt-6 {
  padding-top: 30px !important;
}
.pb-6 {
  padding-bottom: 30px !important;
}
.pl-6 {
  padding-left: 30px !important;
}
.pr-6 {
  padding-right: 30px !important;
}
.pt-7 {
  padding-top: 35px !important;
}
.pb-7 {
  padding-bottom: 35px !important;
}
.pl-7 {
  padding-left: 35px !important;
}
.pr-7 {
  padding-right: 35px !important;
}
.pt-8 {
  padding-top: 40px !important;
}
.pb-8 {
  padding-bottom: 40px !important;
}
.pl-8 {
  padding-left: 40px !important;
}
.pr-8 {
  padding-right: 40px !important;
}
.content-padding {
  padding: 40px;
}
.min-layout-content {
  width: 100%;
  padding-left: 39px;
  padding-right: 39px;
}
@media (min-width: 1300px) {
  .min-layout-content {
    width: 1080px;
    padding: 0;
  }
  .small {
    width: 580px;
  }
}
.d-block {
  display: block;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.font-face {
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-weight: normal;
  font-style: normal;
  src: url('https://fontlibrary.org/assets/fonts/trueno/69d25d10193139d4a9cfc9d2196c23dc/5258bb6653ec14bcbaed8e2c3452ef6c/TruenoLight.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  src: url('https://fontlibrary.org/assets/fonts/trueno/69d25d10193139d4a9cfc9d2196c23dc/49c656db3c7dd8f38a90dd59b777baf8/TruenoLightItalic.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  src: url('https://fontlibrary.org/assets/fonts/trueno/69d25d10193139d4a9cfc9d2196c23dc/7aba95e5ff98d5a275ced8ae58144c5e/TruenoRegular.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  src: url('https://fontlibrary.org/assets/fonts/trueno/69d25d10193139d4a9cfc9d2196c23dc/75e1a3c3d0b921692ddf6a298bedab62/TruenoBold.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  src: url('https://fontlibrary.org/assets/fonts/trueno/69d25d10193139d4a9cfc9d2196c23dc/97519f3659745f54b9ae215c273644bb/TruenoSemiBold.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  src: url('https://fontlibrary.org/assets/fonts/trueno/69d25d10193139d4a9cfc9d2196c23dc/88d5fca36a740ee22e38ea829729e349/TruenoExtraBold.otf') format('opentype');
}
.type-light {
  font-family: 'TruenoLight', Arial, sans-serif;
}
.type-ilight {
  font-family: 'TruenoLightItalic', Arial, sans-serif;
}
.type-regular {
  font-family: 'TruenoRegular', Arial, sans-serif;
}
.type-bold {
  font-family: 'TruenoBold', Arial, sans-serif;
}
.type-sbold {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
}
.type-ebold {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'TruenoExtraBold', Arial, sans-serif !important;
  color: #000000;
}
body {
  font-family: 'TruenoRegular', Arial, sans-serif !important;
  font-weight: normal;
  font-style: normal;
}
.text-primary {
  color: #ffb917;
}
.size-8px {
  font-size: 8px;
}
.size-9px {
  font-size: 9px;
}
.size-10px {
  font-size: 10px;
}
.size-11px {
  font-size: 11px;
}
.size-12px {
  font-size: 12px;
}
.size-14px {
  font-size: 14px;
}
.size-18px {
  font-size: 18px !important;
}
.size-20px {
  font-size: 20px;
}
.spacing-05px {
  letter-spacing: 0.5px;
}
.spacing-1px {
  letter-spacing: 1px;
}
.leading-18px {
  line-height: 18px;
}
.leading-24px {
  line-height: 24px;
}
.leading-23 {
  line-height: 2.33;
}
.uppercase {
  text-transform: uppercase;
}
.clickable {
  cursor: pointer;
}
.text-black {
  color: #000000;
}
.text-black-bis {
  color: #151515;
}
.text-black-ter {
  color: #3A3A3A;
}
.text-gray-darkest {
  color: #747474;
}
.text-gray-darker {
  color: #969696;
}
.text-gray-dark {
  color: #999999;
}
.text-gray {
  color: #A6A6A6;
}
.text-gray-light {
  color: #D8D8D8;
}
.text-gray-lighter {
  color: #D6D6D6;
}
.text-gray-lightest {
  color: #E8E8E8;
}
.text-white {
  color: #FFFFFF;
}
.text-orange {
  color: #FFB917;
}
.bg-black {
  background-color: #000000;
}
.bg-black-bis {
  background-color: #151515;
}
.bg-black-ter {
  background-color: #3A3A3A;
}
.bg-gray-darkest {
  background-color: #747474 !important;
}
.bg-gray-darker {
  background-color: #969696;
}
.bg-gray-dark {
  background-color: #999999;
}
.bg-gray {
  background-color: #A6A6A6;
}
.bg-gray-light {
  background-color: #D8D8D8;
}
.bg-gray-lighter {
  background-color: #D6D6D6;
}
.bg-gray-lightest {
  background-color: #E8E8E8;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-orange {
  background-color: #FFB917;
}
.bg-black {
  background-color: #000000;
}
body {
  background-color: #000000 !important;
}
.ant-layout {
  background: #fff !important;
}
.progress-flat .ant-progress-bg {
  height: 4px !important;
  border-radius: 0px !important;
}
.trend-label {
  color: #747474;
  font-style: italic;
}
.ant-input.trend-input,
.product-form .trend-tag-plain {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #FFB917;
  padding-left: 0;
  padding-bottom: 12px;
}
.ant-input.trend-input:focus {
  border-color: #FFFFFF !important;
}
.ant-input.trend-input.large {
  height: 50px;
  padding-bottom: 15px;
  font-size: 24px;
}
.ant-input.trend-input.dark::placeholder {
  color: #3A3A3A;
}
.ant-input.trend-textarea {
  border: 1px solid #2a2a2a;
  background: #000000;
  border-radius: 0;
  padding: 17px;
  color: #FFB917;
  font-size: 15px;
}
.ant-input.trend-textarea::placeholder {
  color: #3A3A3A;
}
.ant-input.trend-textarea:focus {
  border-color: #FFFFFF !important;
}
.ant-form-item-label label,
.trend-form-label {
  font-size: 13px !important;
  color: #000000;
  font-family: 'TruenoExtraBold', Arial, sans-serif;
}
.trend-select .ant-select-selection {
  border-radius: 50px !important;
  border-color: #a9b0b9 !important;
}
.trend-select .ant-select-selection__placeholder {
  font-size: 10px !important;
  color: #5B6572 !important;
}
.trend-select .ant-select-selection__rendered {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.trend-select-dark .ant-select-selection {
  height: 51px;
  border-radius: 2px;
  background-color: #151515;
  border: 1px solid #3c3c3c;
  color: #747474;
}
.trend-select-dark .ant-select-selection__rendered {
  line-height: 51px;
  margin-left: 15px;
}
.trend-select-dark .ant-select-arrow-icon {
  color: #fff;
}
.trend-radio-group .ant-radio-button-wrapper {
  background: #000000 !important;
  border-radius: 0 !important;
  font-size: 11px !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #fff;
}
.trend-radio-group .ant-radio-button-wrapper:not(:selected) {
  border-color: #494949;
}
.item-listing {
  position: relative;
  width: 25%;
  transition: all 0.18s ease-in;
}
.item-listing .ant-card-bordered {
  border: 0;
  box-shadow: none;
}
.item-listing .ant-card-body {
  box-shadow: 0 0 0 1px #f4f4f4;
}
.item-listing:hover {
  transform: scale(1.05);
  z-index: 2;
}
.item-listing:hover .ant-card-bordered {
  box-shadow: 0 0 14px 0 rgba(176, 176, 176, 0.33);
}
.trend-message-input input::placeholder {
  font-size: 16px;
  color: #000000;
}
.product-form .ant-form-item-label label {
  font-size: 13px;
  font-family: 'TruenoRegular', Arial, sans-serif;
  color: #747474;
}
.product-form .ant-input-number {
  width: 100%;
  height: 40px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #D6D6D6 !important;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #3A3A3A;
  padding-left: 0;
}
.product-form .trend-tag-plain {
  color: #3A3A3A;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  width: 100%;
  cursor: text;
}
.product-form .trend-tag {
  display: inline-block;
  background: #FFB917 !important;
  border-radius: 2px;
  border: 0 !important;
  height: 34px;
  padding: 7px 12px;
  font-size: 11px;
  color: #000000;
}
.product-form .ant-switch[aria-checked="false"] {
  background: #151515;
}
.ant-radio-checked .ant-radio-inner:after {
  transform: scale(1) !important;
}
.ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
  background: #f4f4f4 !important;
  border-color: #dadada !important;
}
.ant-radio-inner:after {
  width: 20px !important;
  height: 20px !important;
  left: -1px !important;
  top: -1px !important;
  border-radius: 30px !important;
}
.plain-radio-group .ant-radio-button-wrapper {
  color: #5B6572;
  font-size: 10px;
  letter-spacing: 0.5px;
  border-color: #a9b0b9;
}
.plain-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #000 !important;
  border-color: #000 !important;
}
.plain-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  opacity: 1;
  background-color: #fff !important;
}
.custom-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: #a9b0b9 !important;
  border-color: #a9b0b9 !important;
  border-radius: 0;
}
.status-tag {
  position: absolute;
  top: 50px;
  left: 0;
  font-family: 'TruenoExtraBold', Arial, sans-serif;
  font-size: 8px;
  background-color: #FFB917;
  padding: 4px 8px;
}
.status-tag span {
  position: relative;
  z-index: 2;
}
.status-tag:after {
  content: '';
  position: absolute;
  z-index: 0;
  top: -5px;
  left: 18px;
  width: 13px;
  height: 13px;
  background-color: #FFB917;
  transform: rotate(45deg);
}
.ant-btn.trend-btn {
  background: #FFB917;
  border: 0;
  color: #000000;
  font-size: 11px;
  font-family: 'TruenoBold', Arial, sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.ant-btn.trend-btn:hover {
  background: #ffe8bc;
}
.ant-btn.trend-btn.secondary {
  height: 35px;
  padding-top: 2px;
  background: #f1f3f7 !important;
  border: 1px solid #dbdfe8;
  box-shadow: none;
  font-size: 9px;
  color: #5B6572;
}
.ant-btn.trend-btn.gray {
  background: #3A3A3A;
  color: #FFFFFF !important;
}
.ant-btn.huge-btn {
  height: 45px!important;
  font-size: 14px !important;
  color: #000000 !important;
  text-transform: none !important;
  letter-spacing: 0;
}
.ant-btn.reject {
  background: #ff5a5a !important;
}
.ant-btn.product-btn {
  height: 57px;
  padding: 16px 68px;
  font-size: 16px;
  font-family: 'TruenoBold', Arial, sans-serif;
  text-transform: none;
  letter-spacing: 0;
}
.action-btn {
  height: 34px;
  padding: 10px 15px;
  background: #000000;
  border: 1px solid #3c3c3c;
  font-size: 11px;
  color: #b0b0b0;
  border-radius: 2px;
  text-transform: uppercase;
  font-family: 'TruenoBold', Arial, sans-serif;
}
.edit-btn {
  position: absolute !important;
  z-index: 2;
  bottom: 15px;
  right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  line-height: 30px !important;
}
.ant-layout-sider,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #000000 !important;
}
.ant-layout {
  height: 100vh;
}
.trend-table .ant-table-thead:before,
.trend-table .ant-table-thead:after {
  content: '';
  position: absolute;
  top: 28px;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.trend-table .ant-table-thead:before {
  left: -100%;
}
.trend-table .ant-table-thead:after {
  right: -100%;
}
.trend-table .ant-table-tbody > tr > td:first-child:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  left: -100%;
}
.trend-table .ant-table-tbody > tr > td:last-child:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  right: -100%;
}
.trend-table .ant-table-thead > tr > th {
  font-size: 8px;
  letter-spacing: 0.5px;
  color: #5B6572 !important;
  background: transparent !important;
  padding: 8px 0 !important;
}
.trend-table .ant-table-thead > tr > th.ant-table-selection-column-custom {
  padding: 4px 0 0 0 !important;
}
.trend-table .ant-table-thead > tr > th div {
  display: inline-block;
}
.trend-table .ant-table-thead > tr > th.influencer-width {
  width: 40%;
}
.trend-table .ant-table-thead > tr > th.post-width {
  width: 15%;
}
.trend-table .ant-table-thead > tr > th.comments,
.trend-table .ant-table-thead > tr > th.likes {
  width: 12%;
}
.trend-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f9f9f9;
}
.trend-table .ant-table-tbody > tr > td {
  font-family: 'truenoregular';
  font-size: 12px;
  color: #000000;
}
.trend-table .ant-table-tbody > tr > td.avatar {
  padding-right: 0;
}
.trend-table .ant-table-tbody > tr > td.post-width .ant-avatar {
  background: #fff;
  width: 50px;
  height: 50px;
  border: 1px dashed #888;
  border-radius: 0;
}
.trend-table .ant-table-tbody > tr > td.influencer-width {
  width: 40%;
}
.trend-table .ant-table-tbody > tr > td.author p:first-child {
  margin: 0;
  padding-top: 5px;
  font-size: 12px;
  font-family: 'truenoextrabold';
  color: #000000;
}
.trend-table .ant-table-tbody > tr > td.author p {
  font-size: 11px;
  color: #5B6572;
}
.trend-table .ant-table-tbody > tr > td.time {
  padding-left: 0;
  width: 15%;
  color: #5B6572;
  font-size: 9px;
  text-align: right;
}
.trend-table .ant-table-tbody > tr > td.count .ant-avatar {
  color: #000000;
  font-size: 9px;
  width: 28px;
  height: 28px;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFB917;
  border-color: #FFB917;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #f4f4f4;
  border: 1px solid #dadada;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-inner:after {
  display: none;
}
.messages-table .ant-table-thead {
  display: none;
}
.ant-table-placeholder {
  border-bottom: 0 !important;
}
.trend-menu-horizontal.ant-menu-horizontal {
  border: 0;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-badge,
.trend-menu-horizontal.ant-menu-horizontal .ant-badge-count {
  font-size: 9px;
  color: #000000;
  font-family: 'TruenoBold', Arial, sans-serif;
  position: absolute;
  left: 0;
  right: 0 !important;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-badge {
  margin-top: -10px;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-badge-count {
  margin: auto;
  padding: 0;
  width: 20px;
  background: #FFB917;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 50px;
  border: 0 !important;
  top: 0 !important;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-menu-item a {
  padding: 12px 0;
  font-size: 12px;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-menu-item:hover {
  padding: 0;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-menu-item:hover a {
  color: #000 !important;
}
.trend-menu-horizontal.ant-menu-horizontal > .ant-menu-item-selected {
  border: 0 !important;
}
.trend-menu-horizontal.ant-menu-horizontal > .ant-menu-item-selected > a {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #000000 !important;
}
.trend-menu-horizontal.ant-menu-horizontal > .ant-menu-item-selected > a:after {
  content: '';
  clear: both;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 31px;
  height: 3px;
  background-color: #FFB917;
}
.account-menu {
  border: 0 !important;
  background: transparent !important;
}
.account-menu > .ant-menu-item > a {
  padding-bottom: 14px;
  font-size: 15px;
  color: #747474 !important;
}
.account-menu > .ant-menu-item-selected > a {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #FFFFFF !important;
}
.trend-menu-sub {
  display: block;
  width: 100%;
  background: transparent !important;
}
.trend-menu-sub .ant-menu-item {
  border: 0 !important;
  padding-bottom: 1px;
  margin-right: 20px !important;
  font-size: 9px;
  font-family: 'TruenoBold', Arial, sans-serif;
}
.trend-menu-sub .ant-menu-item .ant-menu-icon {
  float: left;
  width: 51px;
  height: 51px;
  margin-right: 12px;
  border: 1px solid #a9b0b9;
  background: #fff;
  border-radius: 50%;
}
.trend-menu-sub .ant-menu-item .ant-menu-icon svg {
  stroke: #000000;
}
.trend-menu-sub .ant-menu-item .ant-menu-icon .anticon {
  margin: 0;
  padding: 2px;
}
.trend-menu-sub .ant-menu-item-selected > a:after {
  width: 100% !important;
}
.trend-menu-sub .ant-menu-item-selected .ant-menu-icon {
  border: 0;
  background-color: #000000;
}
.trend-menu-sub .ant-menu-item-selected .ant-menu-icon svg {
  stroke: #fff;
}
.trend-menu-sub .tabs-value {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: 'TruenoBold', Arial, sans-serif;
}
.ant-menu-inline.settings svg {
  float: left;
  margin: 10px 15px 15px 9px;
}
.ant-menu-inline.settings .ant-menu-item a {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 210px;
  height: 65px;
  background: #151515 !important;
  border-top: 1px solid #3c3c3c;
  padding: 12px;
  font-size: 12px !important;
  font-family: 'TruenoRegular', Arial, sans-serif;
}
.ant-menu-inline.secondary {
  padding-bottom: 30px !important;
}
.ant-menu-inline.secondary .ant-menu-item {
  height: 50px !important;
  margin-bottom: 0 !important;
  margin-top: 2px;
}
.ant-menu-inline.secondary .ant-menu-item a {
  padding: 5px 0;
}
.ant-menu-inline.secondary .ant-menu-item svg {
  float: left;
  margin: 8px 15px 15px 8px;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title {
  margin: 0 !important;
  height: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1 !important;
  cursor: default;
  pointer-events: none;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title:hover {
  background: transparent !important;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title,
.ant-menu-inline.trend-menu-inline .ant-menu-item {
  padding-left: 15px !important;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title:hover,
.ant-menu-inline.trend-menu-inline .ant-menu-item:hover {
  background-color: #161616;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title a,
.ant-menu-inline.trend-menu-inline .ant-menu-item a {
  font-size: 10px;
  color: #999999;
}
.ant-menu-inline.trend-menu-inline .ant-menu-sub .ant-menu-item {
  height: 50px;
  margin: 0;
  padding: 5px 0;
  margin-bottom: 4px;
}
.ant-layout-sider-children .ant-menu-item-selected {
  background-color: #2b2b2b !important;
}
.ant-layout-sider-children .ant-menu-item-selected a {
  color: #fff !important;
}
.ant-layout-sider-children .ant-menu-item-selected:after {
  content: '';
  position: absolute;
  right: 0;
  width: 3px;
  height: 100%;
  background-color: #FFB917;
}
.campaign-menu .ant-menu-sub li {
  padding-left: 30px !important;
}
.trend-campaign-avatar {
  position: relative;
  overflow: hidden;
  background: #1f1f1f !important;
}
.trend-campaign-avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-layout-sider-children {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -20px;
  overflow-y: scroll;
}
.post-card.ant-card .ant-card-body .ant-card-meta-title {
  margin-bottom: 0px;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.19) !important;
}
.trend-modal .ant-modal-body {
  padding: 26px;
}
.trend-modal .ant-modal-footer {
  padding: 24px 26px;
  border: 0;
}
.trend-modal .ant-radio-wrapper {
  margin-bottom: 10px !important;
  color: #000000;
}
.trend-modal .ant-radio {
  margin-right: 5px !important;
}
.trend-modal textarea {
  padding: 16px;
  border: 1px solid #D6D6D6 !important;
  line-height: 18px;
  font-size: 12px;
  color: #5B6572 !important;
}
.ant-spin-nested-loading.dark-theme .ant-spin-blur {
  opacity: 0;
}
.stats-heading {
  margin: 0;
  font-family: truenoextrabold;
  font-size: 17px;
  font-style: italic;
  color: #000000;
  line-height: 1.4;
}
.caps-heading {
  font-size: 8px;
  letter-spacing: 1px;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  text-transform: uppercase;
  color: #5B6572;
}
.campaign-image {
  position: relative;
  height: 272px;
  overflow: hidden;
  background: #D6D6D6;
}
.campaign-image img {
  object-fit: cover;
  height: 100%;
}
.campaign-image .trend-btn {
  position: absolute;
  bottom: 15px;
  right: 0;
  box-shadow: -2px 1px 4px 0 rgba(0, 0, 0, 0.28);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.product-uploader {
  position: relative;
  overflow: hidden;
  background: #151515;
  height: 326px;
}
.product-uploader .product-label {
  display: block;
  height: 326px;
  cursor: pointer;
  border: 1px solid #3c3c3c;
  padding: 107px 0;
  color: #747474;
  font-size: 13px;
}
.product-uploader .icon {
  color: #fff;
  padding: 14px;
  background: #222121;
  width: 52px;
  height: 52px;
  font-size: 22px !important;
}
.action-link .back,
.action-link .sign-out {
  position: absolute;
  z-index: 2;
  top: 31px;
}
.action-link .back {
  left: 35px;
}
.action-link .sign-out {
  right: 35px;
}
.product-image {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.trend-post-card .ant-card-cover {
  position: relative;
  overflow: hidden;
  background: #e8e8e8;
  height: 233px;
}
.trend-post-card .ant-card-cover img {
  object-fit: cover;
  height: 100%;
}
.ant-avatar > img {
  object-fit: cover;
}
.plan-radio.ant-radio-wrapper {
  white-space: inherit !important;
  border: 1px solid #747474;
  border-radius: 3px;
  background-color: #151515;
}
.plan-radio.ant-radio-wrapper-checked {
  border: 1px solid #ffb917;
  border-radius: 3px;
}
.plan-radio .ant-radio {
  position: relative;
  left: 90px;
  top: 35px;
}
.StripeElement {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border: 1px solid;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #eee;
  padding-left: 0;
  padding-top: 12px;
  padding-bottom: 5px;
  margin-bottom: 12px;
  font-size: 24px;
}
.StripeElement .input {
  height: 50px;
  color: #FFB917;
}
.StripeElement::placeholder {
  color: #3A3A3A;
}
.add-credits-component .ant-slider .ant-slider-mark {
  margin-top: 20px;
}
.add-credits-component .ant-slider .ant-slider-mark .ant-slider-mark-text {
  color: #747474;
  font-size: 10px;
  font-weight: 900;
}
.add-credits-component .ant-slider .ant-slider-dot {
  visibility: hidden;
}
.add-credits-component .ant-slider .ant-slider-rail {
  border-radius: 0px;
  height: 7px;
}
.add-credits-component .ant-slider .ant-slider-track {
  border-radius: 0px;
  height: 7px;
}
.add-credits-component .ant-slider .ant-slider-handle {
  margin-top: -3px;
}
