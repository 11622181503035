.trend-table {

  .ant-table-thead {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 28px;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
    }

    &:before {
      left: -100%;
    }

    &:after {
      right: -100%;
    }
  }

  .ant-table-tbody > tr > td:first-child{
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
      left: -100%;
    }
  }

  .ant-table-tbody > tr > td:last-child{
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
      right: -100%;
    }
  }

  .ant-table-thead > tr > th {
    &:extend(.ht-5);
    font-size: 8px;
    letter-spacing: 0.5px;
    color: @color-pigeon-dark !important;
    background: transparent !important;
    padding: 8px 0 !important;

    &.ant-table-selection-column-custom {
      padding: 4px 0 0 0 !important;
    }

    div {
      display: inline-block;
    }

    &.influencer-width {
      width: 40%;
    }

    &.post-width {
      width: 15%;
    }

    &.comments,
    &.likes {
      width: 12%;
    }
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f9f9f9;
  }

  .ant-table-tbody > tr > td {
    font-family: 'truenoregular';
    font-size: 12px;
    color: @color-black;

    &.avatar {
      padding-right: 0;
    }
  
    &.post-width {
      .ant-avatar {
        background: #fff;
        width: 50px;
        height: 50px;
        border: 1px dashed #888;
        border-radius: 0;
      }
    }

    &.influencer-width {
      width: 40%;
    }


    &.author {
      p:first-child {
        margin: 0;
        padding-top: 5px;
        font-size: 12px;
        font-family: 'truenoextrabold';
        color: @color-black;
      }

      p {
        font-size: 11px;
        color: @color-pigeon-dark;
      }
    }
  

    &.time {
      padding-left: 0;
      width: 15%;
      color: @color-pigeon-dark;
      font-size: 9px;
      text-align: right;
    }

    &.count {
      .ant-avatar {
        color: @color-black;
        font-size: 9px;
        width: 28px;
        height: 28px;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: @color-orange;
        border-color: @color-orange;
      }
    }

    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background: #f4f4f4;
      border: 1px solid #dadada;
      
      &:after {
        display: none;
      }
    }
  }

}


.messages-table {
  .ant-table-thead {
    display: none;
  }
}



.ant-table-placeholder {
  border-bottom: 0 !important;
}