.trend-menu-horizontal.ant-menu-horizontal {
	border: 0;

	.ant-badge,
	.ant-badge-count {
		font-size: 9px;
		color: @color-black;
		.type-bold();
		position: absolute;
		left: 0;
		right: 0 !important;
	}

	.ant-badge {
		margin-top: -10px;

		&-count {
			margin: auto;
			padding: 0;
			width: 20px;
			background: @color-orange;
		}
	}

	.ant-menu-item {
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-right: 50px;
		border: 0 !important;
		top: 0 !important;

		a {
			padding: 12px 0;
			font-size: 12px;
		}

		&:hover {
			padding: 0;

			a {
				color: #000 !important;
			}
		}
	}

	& > .ant-menu-item-selected {
		border: 0 !important;

		& > a {
			.type-bold();
			color: @color-black !important;

			&:after {
				content: '';
				clear: both;
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 31px;
				height: 3px;
				background-color: @color-orange;
			}
		}
	}
}

.account-menu {
	border: 0 !important;
	background: transparent !important;

	& > .ant-menu-item {
		& > a {
			padding-bottom: 14px;
			font-size: 15px;
			color: #747474 !important;
		}

		&-selected {
			& > a {
				.type-bold();
				color: @color-white !important;
			}
		}
	}
}

.trend-menu-sub {
	display: block;
	width: 100%;
	background: transparent !important;

	.ant-menu-item {
		border: 0 !important;
		padding-bottom: 1px;
		margin-right: 20px !important;
		font-size: 9px;
		.type-bold();

		.ant-menu-icon {
			float: left;
			width: 51px;
			height: 51px;
			margin-right: 12px;
			border: 1px solid #a9b0b9;
			background: #fff;
			border-radius: 50%;

			svg {
				stroke: @color-black;
			}

			.anticon {
				margin: 0;
				padding: 2px;
			}
		}

		&-selected {
			& > a {
				&:after {
					width: 100% !important;
				}
			}

			.ant-menu-icon {
				border: 0;
				background-color: @color-black;

				svg {
					stroke: #fff;
				}
			}
		}
	}

	.tabs-value {
		display: block;
		font-size: 16px;
		font-style: italic;
		.type-bold(); 
	}
}


// Sider

.ant-menu-inline {
	&.settings {
		svg {
		    float: left;
    		margin: 10px 15px 15px 9px;
		}

		.ant-menu-item {
			a {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 210px;
				height: 65px;
				background: #151515 !important;
				border-top: 1px solid #3c3c3c;
				padding: 12px;
				.size-12px() !important;
				.type-regular();
			}
		}
	}

	&.secondary {
		.pb-6();

		.ant-menu-item {
			height: 50px !important;
			margin-bottom: 0 !important;
			margin-top: 2px;

			a {
				padding: 5px 0;
			}

			svg {
				float: left;
				margin: 8px 15px 15px 8px;
			}
		}
	}

	&.trend-menu-inline {
		.ant-menu-submenu-title {
			margin: 0 !important;
			height: 100% !important;
			padding-top: 10px;
    		padding-bottom: 10px;
    		line-height: 1 !important;
			cursor: default;
			pointer-events: none;

			&:hover {
				background: transparent !important;
			}
		}

		.ant-menu-submenu-title,
		.ant-menu-item {
			padding-left: 15px !important;

			&:hover {
				background-color: #161616;
			}
			
			a {
				.size-10px();
				.text-gray-dark();
			}
		}

		.ant-menu-sub {
			.ant-menu-item {
				height: 50px;
				margin: 0;
				padding: 5px 0;
				margin-bottom: 4px;
			}
		}
	}
}

.ant-layout-sider-children {
	.ant-menu-item-selected {
		background-color: #2b2b2b !important;

		a {
			color: #fff !important;
		}

		&:after {
			content: '';
			position: absolute;
			right: 0;
			width: 3px;
			height: 100%;
			background-color: @color-orange;
		}
	}
}

.campaign-menu .ant-menu-sub {
  li {
    padding-left: 30px !important;
  }
}

.trend-campaign-avatar {
	position: relative;
	overflow: hidden;
	background: #1f1f1f !important;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

// Sidebar

.ant-layout-sider-children {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: -20px;
	overflow-y: scroll;
}