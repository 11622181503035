.stats-heading {
	margin: 0; 
	font-family: truenoextrabold; 
	font-size: 17px; 
	font-style: italic; 
	color: @color-black;
	line-height: 1.4;
}

.caps-heading {
	.size-8px();
	.spacing-1px();
	.type-sbold();
	.uppercase();
	color: @color-pigeon-dark;
}

.campaign-image {
	position: relative;
	height: 272px;
	overflow: hidden;
	background: @color-gray-lighter;

	img {
		object-fit: cover;
		height: 100%;
	}

	.trend-btn {
		position: absolute;
		bottom: 15px;
		right: 0;
		box-shadow: -2px 1px 4px 0 rgba(0, 0, 0, 0.28);
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}