.ant-modal-mask {
	background-color: rgba(0, 0, 0, 0.19) !important;
}

.trend-modal {
	.ant-modal {
		&-body {
			padding: 26px;
		}

		&-footer {
			padding: 24px 26px;
			border: 0;
		}
	}

	.ant-radio-wrapper {
		.mb-2();
		color: @color-black;
	}

	.ant-radio {
		.mr-1();
	}

	textarea {
		padding: 16px;
		border: 1px solid @color-gray-lighter !important;
		.leading-18px();
		.size-12px();
		color: @color-pigeon-dark !important;
	}
}