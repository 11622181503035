.trend-label {
  color: #747474;
  font-style: italic;
}

.ant-input {
  &.trend-input {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-color: #2a2a2a !important;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none !important;
    color: @color-orange;
    padding-left: 0;
    padding-bottom: 12px;

    &:focus {
      border-color: @color-white !important;
    }

    &.large {
      height: 50px;
      padding-bottom: 15px;
      font-size: 24px;
    }

    &.dark {
      &::placeholder {
        color: @color-black-ter;
      }
    }
  }

  &.trend-textarea {
    border: 1px solid #2a2a2a;
    background: @color-black;
    border-radius: 0;
    padding: 17px;
    color: @color-orange;
    font-size: 15px;

    &::placeholder {
      color: @color-black-ter;
    }

    &:focus {
      border-color: @color-white !important;
    }
  }
}

.ant-form-item-label label,
.trend-form-label {
  font-size: 13px !important;
  color: @color-black;
  .type-ebold();
}

// Select

.trend-select {
  .ant-select-selection {
    border-radius: 50px !important;
    border-color: #a9b0b9 !important;
  }

  .ant-select-selection__placeholder {
    font-size: 10px !important;
    color: @color-pigeon-dark !important;
  }

  .ant-select-selection__rendered {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  &-dark {
    .ant-select-selection {
      height: 51px;
      border-radius: 2px;
      background-color: #151515;
      border: 1px solid #3c3c3c;
      color: #747474;
    }

    .ant-select-selection__rendered {
      line-height: 51px;
      margin-left: 15px;
    }

    .ant-select-arrow-icon {
      color: #fff;
    }
  
  }
}

// Radio

.trend-radio-group {
  .ant-radio-button-wrapper {
    background: @color-black !important;
    
    border-radius: 0 !important;
    font-size: 11px !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #fff;

    &:not(:selected) {
      border-color: #494949;
    }
  }
}


.item-listing {
  position: relative;
  width: 25%;
  transition: all .18s ease-in; 

  .ant-card-bordered {
    border: 0;
     box-shadow: none;
  }

  .ant-card-body {
    box-shadow: 0 0 0 1px #f4f4f4;
  }


  &:hover {
    .ant-card-bordered {
      box-shadow: 0 0 14px 0 rgba(176, 176, 176, 0.33);;
    }

    transform: scale(1.05);
    z-index: 2;
  }
}

.trend-message-input {
  input::placeholder {
    font-size: 16px;
    color: @color-black;
  }
}

.product-form {
  .ant-form-item-label {
    label {
      font-size: 13px;
      .type-regular();
      color: #747474;
    }
  }

  .ant-input-number {
    width: 100%;
    height: 40px;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-color: @color-gray-lighter !important;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none !important;
    color: @color-black-ter;
    padding-left: 0;   
  }

  .trend-tag-plain {
    &:extend(.ant-input.trend-input);
    color: @color-black-ter;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
    width: 100%;
    cursor: text;
  }

  .trend-tag {
    display: inline-block;
    background: @color-orange !important;
    border-radius: 2px;
    border: 0 !important;
    height: 34px;
    padding: 7px 12px;
    font-size: 11px;
    color: @color-black;
  }

  .ant-switch {
    &[aria-checked="false"] {
        background: #151515;
    }
  }
}

// Radio

.ant-radio {
  &-checked {
    .ant-radio-inner {
      &:after {
        transform: scale(1) !important;
      }
    }
  }

  &-inner {
    width: 20px !important;
    height: 20px !important;
    background: #f4f4f4 !important;
    border-color: #dadada !important;

    &:after {
      width: 20px !important;
      height: 20px !important;
      left: -1px !important;
      top: -1px !important;
      border-radius: 30px !important;
    }
  }
}

.plain-radio-group {
  .ant-radio-button-wrapper {
    color: @color-pigeon-dark;
    .size-10px();
    .spacing-05px();
    border-color: #a9b0b9;
  }

  .ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
      background: #000 !important; 
      border-color: #000 !important;

      &:before {
        opacity: 1;
        background-color: #fff !important;
      }
    }

  }
}

.custom-checkbox {
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-color: #a9b0b9 !important;
    border-color: #a9b0b9 !important;
    border-radius: 0;
  }
}




.status-tag {
  .p-absolute();
  top: 50px;
  left: 0;
  .type-ebold();
  .size-8px();
  background-color: @color-orange;
  padding: 4px 8px;

  span {
    .p-relative();
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: -5px;
    left: 18px;
    width: 13px;
    height: 13px;
    background-color: @color-orange; 
    transform: rotate(45deg);
  }
}
