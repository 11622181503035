.ant-btn {
  &.trend-btn {
    background: @color-orange;
    border: 0;
    color: @color-black;
    font-size: 11px;
    .type-bold();
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:hover {
      background: #ffe8bc;
    }

    &.secondary {
      height: 35px;
      padding-top: 2px;
      background: #f1f3f7 !important;
      border: 1px solid #dbdfe8;
      box-shadow: none;
      font-size: 9px;
      color: @color-pigeon-dark;
    }

    &.gray {
      background: @color-black-ter;
      color: @color-white !important;
    }
  }

  &.huge-btn {
    height: 45px!important;
    .size-14px() !important;
    color: @color-black !important;
    text-transform: none !important;
    letter-spacing: 0;
  }

  &.reject {
    background: #ff5a5a !important;
  }

  &.product-btn {
    height: 57px;
    padding: 16px 68px;
    font-size: 16px;
    .type-bold();
    text-transform: none;
    letter-spacing: 0;
  }
}

.action-btn {
  height: 34px;
  padding: 10px 15px;
  background: @color-black;
  border: 1px solid #3c3c3c;
  font-size: 11px;
  color: #b0b0b0;
  border-radius: 2px;
  text-transform: uppercase;
  .type-bold();
}

// Temporary

.edit-btn {
  position: absolute !important;
  z-index: 2;
  bottom: 15px;
  right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  line-height: 30px !important;
}
