.pt-0 { padding-top:          0 !important }
.pb-0 { padding-bottom:       0 !important }
.pl-0 { padding-left:         0 !important }
.pr-0 { padding-right:        0 !important }

.pt-1 { padding-top:          @unit }
.pb-1 { padding-bottom:       @unit }
.pl-1 { padding-left:         @unit }
.pr-1 { padding-right:        @unit }

.pt-2 { padding-top:          2*@unit }
.pb-2 { padding-bottom:       2*@unit }
.pl-2 { padding-left:         2*@unit }
.pr-2 { padding-right:        2*@unit }

.pt-3 { padding-top:          3*@unit }
.pb-3 { padding-bottom:       3*@unit }
.pl-3 { padding-left:         3*@unit }
.pr-3 { padding-right:        3*@unit }

.pt-4 { padding-top:          4*@unit }
.pb-4 { padding-bottom:       4*@unit }
.pl-4 { padding-left:         4*@unit }
.pr-4 { padding-right:        4*@unit }

.pt-5 { padding-top:          5*@unit }
.pb-5 { padding-bottom:       5*@unit }
.pl-5 { padding-left:         5*@unit }
.pr-5 { padding-right:        5*@unit }

.pt-6 { padding-top:          6*@unit }
.pb-6 { padding-bottom:       6*@unit }
.pl-6 { padding-left:         6*@unit }
.pr-6 { padding-right:        6*@unit }

.pt-7 { padding-top:          7*@unit }
.pb-7 { padding-bottom:       7*@unit }
.pl-7 { padding-left:         7*@unit }
.pr-7 { padding-right:        7*@unit }

.pt-8 { padding-top:          8*@unit }
.pb-8 { padding-bottom:       8*@unit }
.pl-8 { padding-left:         8*@unit }
.pr-8 { padding-right:        8*@unit }