.plan-radio {
  &.ant-radio-wrapper {
    white-space: inherit !important;
    border: 1px solid @color-gray-darkest;
    border-radius: 3px;
    background-color: @color-black-bis;
  }

  &.ant-radio-wrapper-checked {
    border: 1px solid @primary-color;
    border-radius: 3px;
  }

  .ant-radio {
    position: relative;
    left: 90px;
    top: 35px;
  }
}


.StripeElement {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border: 1px solid;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #eee;
  padding-left: 0;
  padding-top: 12px;
  padding-bottom: 5px;
  margin-bottom: 12px;

  .input {
    height: 50px;
    color: @color-orange;
  }
  font-size: 24px; 
  &::placeholder {
    color: @color-black-ter;
  }
}
