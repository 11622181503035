.add-credits-component {
  .ant-slider {
    .ant-slider-mark {
      margin-top: 20px;
      .ant-slider-mark-text {
        color: @color-gray-darkest;
        font-size: 10px;
        font-weight: 900;
      }
    }
    .ant-slider-dot {
      visibility: hidden;
    }
    .ant-slider-rail{
      border-radius: 0px;
      height: 7px;
    }
    .ant-slider-track{
      border-radius: 0px;
      height: 7px;
    }
    .ant-slider-handle {
      margin-top: -3px;
    }
  }
}

